
import { defineComponent, onMounted, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import addressEditFormModal from "@/components/AddressEditFormModal.vue";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "address-table",
  components: { addressEditFormModal },
  props: {
    widgetClasses: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
   

    const slaveId = computed(() => store.getters.curSlaveId);
    const addressId = computed(() => store.getters.curAddressId);
    //let slaveTemplateId = store.getters.curSlaveTemplateId

    //var addressToEdit = ref<number>(0);
    const addresses = ref<Mast.Address[]>([] as Mast.Address[]);

    const initAddressEditForm = ref<number>(0);

    const getAddresses = () => {
      const postData = {
        searchString: "",
        parentIds: [slaveId.value],
        pageSize: 10000,
        pageNumber: "",
      } as any;

      ApiService.post("/device/addresses", postData)
        .then(({ data }) => {
          console.log("getaddressesresponse data:");
          console.log({ ...data });
          addresses.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getaddresses");
        });
    };

    const clickNewAddress = () => {
      if (slaveId.value == "0") {
        Swal.fire({
          title: "Submit new slave before adding addresses",
          icon: "warning",
          buttonsStyling: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          let modal = document.getElementById("modal_edit_address");
          hideModal(
            document.getElementById("modal_edit_address") as HTMLElement
          ); //Todo kan beter om de toch geopende modal te sluiten
          return;
        });
      }
      store.commit("setAddressId", 0);
      console.log("goNewAddress");
      //addressToEdit.value = 0;//todo kan weg?
      initAddressEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const clickEditAddress = (data) => {
      console.log("goEditAddress");
      console.log(data.id);
      store.commit("setAddressId", data.id);
      //addressToEdit.value = data.id; //todo kan weg?
      initAddressEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const cancelAddressEdit = () => {
      console.log("cancelEditSerie");
      //addressToEdit.value = 0;
    };

    const addressUpdated = () => {
      console.log("serieUpdated");
      //addressToEdit.value = 0;
      getAddresses();
    };

    const clickDeleteAddress = (data: Mast.Address) => {
      Swal.fire({
        title: "Are you sure to delete address " + data.displayName + " ?",
        showCancelButton: true,
        icon: "warning",
        buttonsStyling: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteAddress(data);
        }
      });
    };

    const deleteAddress = (data) => {
      console.log("deleteAddress:");
      console.log(data);

      ApiService.post("/device/addressdelete", data as any)
        .then(({ data }) => {
          console.log("delete address response data:");
          console.log({ ...data });
          getAddresses();
        })
        .catch(({ response }) => {
          console.log("Fout addressdelete");
        });
    };

    onMounted(() => {
      getAddresses();
    });

    return {
      addresses,
      clickNewAddress,
      clickEditAddress,
      clickDeleteAddress,
      //addressToEdit,
      initAddressEditForm,
      addressUpdated,
      cancelAddressEdit,
      t,
    };
  },
});
