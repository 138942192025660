
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "address-edit-form-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    initAddressEditForm: {
      required: true,
      type: Number,
    },
  },
  emits: ["addressUpdated", "cancelAddressEdit"],

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditAddressRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const slaveId = computed(() => store.getters.curSlaveId);
    const addressId = computed(() => store.getters.curAddressId);

    const getEmptyAddress = () => {
      return {
        id: 0,
        displayName: "",
        slaveId: slaveId.value,
        function: {
          id: 0,
          genericOptionTypeId: 0,
          int: 0,
          float: 0,
          string: "",
          selected: false,
        },
        functionId: 0,
        addressValue: 0,
        decimals: 0,
        dataType: {
          id: 0,
          genericOptionTypeId: 0,
          int: 0,
          float: 0,
          string: "",
          selected: false,
        },
        dataTypeId: 0,
        addressTemplateId: 0,
        unit: "",
        intervalId: 0,
      } as Mast.Address;
    };

    const newAddressData = ref<Mast.Address>(getEmptyAddress());
    const slaveData = ref<Mast.Slave>({} as Mast.Slave);

    const intervalsData = ref<Mast.GenericOption[]>([] as Mast.GenericOption[]);
    const emptySlaveTemplate = {} as Mast.SlaveTemplate;
    const slaveTemplateData = ref<Mast.SlaveTemplate>(emptySlaveTemplate);

    const validationSchema = Yup.object().shape({
      displayName: Yup.string().required().label("Name"),
      addressTemplateId: Yup.number().required().min(1).label("Address"),
      unit: Yup.string().required().label("Unit"),
    });

    const { initAddressEditForm } = toRefs(props);
    const addressTemplates = ref<Mast.AddressTemplate[]>(
      [] as Mast.AddressTemplate[]
    );

    const addressChange = (e) => {
      console.log(e);
      //
      if (e.target.options.selectedIndex > -1) {
        newAddressData.value.displayName =
          e.target.options[e.target.options.selectedIndex].text;
        newAddressData.value.unit =
          addressTemplates.value[e.target.options.selectedIndex].unit;
      }
    };

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if (newAddressData.value.intervalId == 0)
        newAddressData.value.intervalId = undefined;

      ApiService.post("/device/addressupdate", newAddressData.value as any)
        .then(({ data }) => {
          console.log("submit Address response data:"); //todo geert
          console.log(data); //todo geert
          // router.push({ name: "editschart",params: {slaveId:data.slaveId} });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("addressUpdated");
          hideModal(modalEditAddressRef.value);
        })
        .catch(({ response }) => {
          //todo algemene foutfunctie maken voor in de catches
          console.log("Fout addressUpdate");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.data.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const getAddressTemplatesBySlaveId = (slaveId, addressId) => {
      let tmpAddressId;
      if (addressId > 0) tmpAddressId = addressId;
      else tmpAddressId = ""; //todo dit moet in backend
      return ApiService.get2(
        "/device/addresstemplates?slaveId=" +
          slaveId +
          "&addressId=" +
          tmpAddressId
      )
        .then(({ data }) => {
          console.log("getaddresstemplatesresponse data:");
          console.log({ ...data });
          addressTemplates.value = data;
        })
        .catch(({ response }) => {
          console.log("Fout getaddresstemplates");
        });
    };

    const getAddressById = (id) => {
      if (id == 0) {
        newAddressData.value = getEmptyAddress();

        console.log("newAddresData");
        console.log(newAddressData.value);
        return;
      }

      return ApiService.get2("/device/addressget?id=" + id)
        .then(({ data }) => {
          console.log("getAddress response data:"); //todo geert
          console.log({ ...data }); //todo geert  regel hieronder mag weg als Dolf gefixed heeft
          // if (!data.intervalId) {
          //   data.intervalId = 0;
          // }
          newAddressData.value = data as Mast.Address;
          if (newAddressData.value.intervalId == undefined)
            newAddressData.value.intervalId = 0;
        })
        .catch(({ response }) => {
          console.log("Fout getAddressById");
        });
    };

    const getSlaveById = (id) => {
      console.log("getSlaveById id:");
      console.log(id);

      return ApiService.get2("/device/slaveget?id=" + id)
        .then(({ data }) => {
          console.log("getSlave response data:"); //todo geert
          console.log(data); //todo geert
          slaveData.value = data as Mast.Slave;
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveById");
        });
    };

    const getSlaveTemplateById = (id) => {
      console.log("getSlaveTemplateById id:");
      console.log(id);

      return ApiService.get2("/template/slavetemplateget?id=" + id)
        .then(({ data }) => {
          console.log("getSlaveTemplate response data:"); //todo geert
          console.log(data); //todo geert
          slaveTemplateData.value = data as Mast.SlaveTemplate;
          intervalsData.value = slaveTemplateData.value.intervals;
          console.log("intervaldata");
          console.log(intervalsData.value);
          //const slaveData = ref<Mast.Slave>({} as Mast.Slave);
          //const arr  = ref<Mast.GenericOption[]>(  [] as Mast.GenericOption[]);
          //const arr0 = Mast.GenericOption

          const temp = {
            id: 0,
            string: "",
            genericOptionTypeId: 2,
            int: null,
            float: null,
          } as any as Mast.GenericOption;
          //temp.id=0;
          //temp.string = "-----";

          intervalsData.value.splice(0, 0, temp);
          //console.log(intervalsData.value);
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveTemplateById");
        });
    };

    watch(initAddressEditForm, () => {
      console.log("get AddressTemplates: ");
      console.log(slaveId);
      console.log(addressId);

      getAddressTemplatesBySlaveId(slaveId.value, addressId.value)
        .then(() => {
          return getAddressById(addressId.value);
          //console.log("end AddressByIdById");
        })
        .then(() => {
          if (!newAddressData.value.slaveId) return;

          console.log("start getSlaveById");
          return getSlaveById(newAddressData.value.slaveId);
          //console.log("end getSlaveById");
        })
        .then(() => {
          if (!slaveData.value.slaveTemplateId) return;

          console.log("start getSlaveTemplate");
          console.log(slaveData.value.slaveTemplateId);
          return getSlaveTemplateById(slaveData.value.slaveTemplateId);
          //console.log("end getSlaveTemplate");
        });
    });

    return {
      addressChange,
      newAddressData,
      validationSchema,
      submit,
      submitButtonRef,
      modalEditAddressRef,
      addressTemplates,
      slaveId,
      addressId,
      t,
      intervalsData,
      slaveTemplateData,
    };
  },
});
