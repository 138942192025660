
    import { defineComponent, onMounted} from "vue";
    //import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
    import slaveEditform from "@/components/SlaveEditForm.vue";
    import { setCurrentPageTitle,setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    //import { MenuComponent } from "@/assets/ts/components";
    //import masterstable from "@/components/MastersTable.vue"

    export default defineComponent({
        name: "slave-edit-page",
        components: {
            slaveEditform,

        },
        setup() {
            onMounted(() => {
                //setCurrentPageBreadcrumbs("Edit slave", ["Widgets","Aap"]); Als deze nodig is dan setCurrentPageTitle in commentaar zetten
                setCurrentPageTitle("Edit Slave");
                //MenuComponent.reinitialization();
            });

                    
            return {
                slaveEditform
            };
        },


    });
