
import { defineComponent, ref, onMounted, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import AddressTable from "@/components/addressTable.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "slave-edit-form",
  components: {
    //KTModalCard,
    AddressTable,
    //AddressEditForm,
    ErrorMessage,
    Field,
    Form,
  },
  props: {},

  setup(props) {
    const router = useRouter();
    const { t} = useI18n();
    
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    const slaveTemplates = ref<Mast.SlaveTemplate[]>([
      {} as Mast.SlaveTemplate,
    ]);

    //const selectedSlaveTemplates =  ref<Mast.SlaveTemplate>({} as Mast.SlaveTemplate)
    const store = useStore();

    const masterId = computed(() => store.getters.curMasterId);
    const slaveId = computed(() => store.getters.curSlaveId);

    let oldStationNumber: number | undefined = 0;

    var NewSlaveData = ref<Mast.Slave>({
      id: 0,
      stationOptions: [],
      masterId: masterId.value,
      station: 0,
      interval: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      protocol: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      protocolId: 0,
      ipAddress: "",
      port: 0,
      displayName: "",
      description: "",
      addresses: [],

      slaveTemplateId: 0,
      slaveTemplate: {
        id: 0,
        name: "",
        networks: [],
        defaultNetwork: {
          id: 0,
          genericOptionTypeId: 0,
          int: 0,
          float: 0,
          string: "",
          selected: false,
        },
        defaultNetworkId: 0,
        baudRates: [],
        defaultBaudrateId: 0,
        protocols: [],
        defaultProtocol: {
          id: 0,
          genericOptionTypeId: 0,
          int: 0,
          float: 0,
          string: "",
          selected: false,
        },
        defaultProtocolId: 0,
        intervals: [],
        intervalId: 0,
        addressTemplates: [],
      },
    });

    const selectedSlaveTemplate = computed(() => {
      let tmpSlaveTemplates = slaveTemplates.value.filter(
        (slaveTemplate) =>
          slaveTemplate.id == NewSlaveData.value.slaveTemplateId
      );
      if (tmpSlaveTemplates.length > 0) {
        console.log("returning:");
        console.log(tmpSlaveTemplates[0]);
        return tmpSlaveTemplates[0];
      } else {
        console.log("returning empty");
        return {
          id: 0,
          name: "",
          networks: [],
          defaultNetwork: {
            id: 0,
            genericOptionTypeId: 0,
            int: 0,
            float: 0,
            string: "",
            selected: false,
          },
          defaultNetworkId: 0,
          baudRates: [],
          defaultBaudrateId: 0,
          protocols: [],
          defaultProtocol: {
            id: 0,
            genericOptionTypeId: 0,
            int: 0,
            float: 0,
            string: "",
            selected: false,
          },
          defaultProtocolId: 0,
          intervals: [],
          intervalId: 0,
          addressTemplates: [],
        } as Mast.SlaveTemplate;
      }
    });

    const validationSchema = Yup.object().shape({
      station: Yup.number().min(1).label("station"),
      interval: Yup.number().required().label("interval"),
      protocol: Yup.number().required().min(1).label("protocol"),
      ipAddress: Yup.string().label("ipAddress"),
      //port: Yup.number(),
      displayName: Yup.string().required().label("displayName"),
      description: Yup.string(),
      slaveTypeId: Yup.number().required().min(1).label("slaveTypeId"),
    });

    const getSlaveTemplates = () => {
      const postData = {
        //todo geert  tijdelijke params
        searchString: "",
        parentIds: [],
        pageSize: 10000,
        pageNumber: "",
      } as any;
      return ApiService.post("/template/slavetemplates", postData)
        .then(({ data }) => {
          console.log("getSlaveTemplates response data:"); //todo geert
          console.log({ ...data }); //todo geert
          slaveTemplates.value = data.results as Mast.SlaveTemplate[];
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveTemplates");
        });
    };

    const getSlaveById = (id) => {
      console.log("getSlaveById id:");
      console.log(id);

      ApiService.get2("/device/slaveget?id=" + id)
        .then(({ data }) => {
          console.log("getSlave response data:"); //todo geert
          console.log(data); //todo geert
          NewSlaveData.value = data as Mast.Slave;
          oldStationNumber = NewSlaveData.value.station;
          store.commit(
            "setSlaveTemplateId",
            NewSlaveData.value.slaveTemplateId
          ); //hoeft niet als hele slave object in store
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveById");
        });
    };

    const clickTest = () => {
      console.log("neWSlaveData:");
      console.log(NewSlaveData.value.addresses);
    };

    const submit = () => {
      //console.log("NewSlavedata");
      //console.log(NewSlaveData.value);
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button and busy indicator
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/device/slaveupdate", NewSlaveData.value as any)
        .then(({ data }) => {
          console.log("submit Slave response data:"); //todo geert
          console.log({ ...data }); //todo geert
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          router.push({ name: "editmaster" });
        })
        .catch(({ response }) => {
          console.log("Fout SlaveUpdate");
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            text: ApiService.getModelStateError(response.data.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

    const clickCancel = () => {
      router.go(-1);
    };

    const stationChange = (e) => {
      
      if (e.target.options.selectedIndex > -1) {
        if (
          e.target.options[e.target.options.selectedIndex].text.length > 6 &&
          e.target.options[e.target.options.selectedIndex].value !=
            oldStationNumber
        ) {
          Swal.fire({
            title: t("switchStationNumber"),
            showCancelButton: true,
            icon: "warning",
            buttonsStyling: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (!result.isConfirmed) {
              NewSlaveData.value.station = oldStationNumber;
              
            }
          });
        }
      }
    };

    const getStationOptions = (id) => {
      console.log("getStationOptions:");
      console.log(id);

      ApiService.get2("/device/stationoptionsget?masterId=" + id)
        .then(({ data }) => {
          console.log("getStaionOptions response data:"); //todo geert
          console.log(data); //todo geert
          NewSlaveData.value.stationOptions = data as Mast.StationOption[];
        })
        .catch(({ response }) => {
          console.log("Fout getStationOptions");
        });
    };

    onMounted(() => {
      getSlaveTemplates().then(() => {
        if (slaveId.value != 0) {
          getSlaveById(slaveId.value);
        } else {
          //Als nwe slave dan hebben we nog geen stationoptions.
          getStationOptions(NewSlaveData.value.masterId);
        }
      });

      // if(slaveId.value !=0)
      // {
      //   getSlaveById( slaveId.value)
      //   console.log("NewSlaveData is nu:")
      //   console.log(NewSlaveData.value.displayName)
      // }
    });

    return {
      stationChange,
      clickTest,
      NewSlaveData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      clickCancel,
      slaveTemplates,
      selectedSlaveTemplate,
      t,
    };
  },
});
